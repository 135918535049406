export const PRICING = {
    ACHIEVER: {
        PRICE: 'Free',
    },
    EXPERT: {
        PRICE: 'Free',
    },
    MEMBER: {
        PRICE: '10000',
    },
};

export const STORAGE_KEYS = {
    PAYMENT_FORM_DETAILS: 'tli-user-form-details',
};

export const ROLES = {
    STUDENT: 'achiever',
    EXPERT: 'expert',
    MEMBER: 'member',
    ADMIN: 'admin',
};

export const currency = { NGN: '₦', USD: '$' };
